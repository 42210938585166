import { Mail } from "@styled-icons/octicons";
import { Modal } from "@components/Modal";
import {
  VerticalStack,
  HorizontalStack,
  ContainerContent,
} from "@components/Layout";
import { SubscribeForm } from "@components/SubscribeForm";
import { Callout, CleanCallout } from "@components/Text";
import {
  Spinner,
  ModalContainerContent,
  ModalHeader,
  ModalSubline,
} from "./styled";

interface TransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  status: "pending" | "success" | "error";
  message: string;
  title?: string;
  image?: string;
}

const TransactionModal = ({
  isOpen,
  onClose,
  status,
  message,
  title,
  image,
}: TransactionModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} image={image}>
      {status === "pending" && (
        <ModalContainerContent>
          <VerticalStack>
            <Spinner />
            <p>{message}</p>
          </VerticalStack>
        </ModalContainerContent>
      )}
      {status === "success" && (
        <ModalContainerContent>
          <ContainerContent>
            <HorizontalStack>
              <VerticalStack>
                <ModalHeader>{message}</ModalHeader>
                <ModalSubline>
                  View your benefits on the edition page
                </ModalSubline>
                <CleanCallout>
                  <Mail size="16" />
                  &nbsp; Get notified for next month's drop!
                  <SubscribeForm thin />
                </CleanCallout>
              </VerticalStack>
            </HorizontalStack>
          </ContainerContent>
        </ModalContainerContent>
      )}
      {status === "error" && (
        <ModalContainerContent>
          <VerticalStack>
            <p>{message}</p>
          </VerticalStack>
        </ModalContainerContent>
      )}
    </Modal>
  );
};

export default TransactionModal;
