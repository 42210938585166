import {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import { editions } from "@apis/editions";

export interface IEdition {
  instanceId: number;
  startTime: number;
  endTime: number;
  month: string;
  year: number;
  split: {
    collector: number;
    project: number;
    artist: number;
    community: number;
    splitsPage: string;
  };
  editionPage: {
    price: string;
    name: string;
    nftDescription: string;
    splitImages: string[];
    openseaUrl: string;
  };
  artistInfo: {
    name: string;
    description: string;
    flag: string;
    twitter: string;
    instagram: string;
    email: string;
    foundation: string;
    opensea?: string;
    sealed?: string;
    portfolio: string;
    skills: string[];
  };
  projectInfo: {
    imageURL: string;
    description: string;
    communityMembers: Array<{
      character: string;
      image: string;
      name: string;
      twitter: string;
    }>;
    communityName: string;
    communityTwitter: string;
    communityInstagram?: string;
    communityDiscord: string;
    communityWebsite: string;
    communityOpensea: string;
    hasWallpapers: boolean;
  };
  benefits: {
    allWallpapersCID: string;
    showcaseURL: string;
  };
}

const EditionsContext = createContext<IEdition | null>(null);

export const EditionsProvider = ({ children }: { children: ReactNode }) => {
  const [latestEdition, setLatestEdition] = useState<IEdition | null>(null);

  useEffect(() => {
    const fetchEditions = async () => {
      try {
        const data = await editions.index();
        if (data && data.length > 0) {
          const latestIndex = data.length - 1;
          if (Math.round(+new Date()/1000) > data[latestIndex].startTime) {
            setLatestEdition(data[latestIndex]);
          } else {
            setLatestEdition(data[latestIndex - 1]);
          }
        }
      } catch (error) {
        const message =
          error instanceof Error ? error.message : "An unknown error occurred";
        console.error(message);
      }
    };

    fetchEditions();
  }, []);

  return (
    <EditionsContext.Provider value={latestEdition}>
      {children}
    </EditionsContext.Provider>
  );
};

export const useLatestEdition = () => {
  return useContext(EditionsContext);
};
